import React from "react";
import "./App.scss";

import About from "./components/About/About";
import Cards from "./components/Cards/Cards";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import CTA from "./components/CTA/CTA";
import Sponsors from "./components/Sponsors/Sponsors";
import Form from "./components/Form/Form";
// import Features from "./components/Features/Features";
// import Notifications from "./components/Notifications/Notifications";

// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Dashboard from "./pages/Dashboard/Dashboard";
// import Login from "./pages/Login/Login";
// import HeroDash from "./components/HeroDash/HeroDash";

function App() {
  return (
    <div className="App">
      {/* <Notifications /> */}
      {/* <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <>
                <HeroDash />
                <Dashboard />
                <Footer />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <Hero />
                <About />
                <Cards />
                <Sponsors />
                <CTA />
                <Features />
                <Form />
                <Footer />
              </>
            }
          />
        </Routes>
      </Router> */}
      <Hero />
      <About />
      <Cards />
      <Sponsors />
      <CTA />
      {/* <Features /> */}
      <Form />
      <Footer />
    </div>
  );
}

export default App;
